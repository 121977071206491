import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`контакты`, `команда`, `софтмакс`, `о компании`]}
        title='Контакты'
      />
      <section className='text-center'>
        {/* <p className='text-xl text-blue-800'>тел. +7 911 832-02-10</p> */}
        <p className=' font-bold text-2xl text-red-800 uppercase tracking-wider'>
          email: softmax86@mail.ru
        </p>
        <form
          className='mx-auto md:w-1/2'
          //onSubmit={submitForm}
          action='https://formspree.io/mdolweve'
          method='POST'
        >
          <p className='mb-8 leading-loose text-lg'>
            Для заказа продукта или услуги, пожалуйста, отправьте форму
          </p>

          <input
            className='w-full mb-6 form-input'
            name='first-name'
            placeholder='Ваше имя или название компании...'
            type='text'
          />

          <input
            className='w-full mb-6 form-input'
            name='_replyto'
            placeholder='Электронная почта для обратной связи'
            type='text'
          />

          <textarea
            className='w-full mb-6 form-textarea'
            name='message'
            placeholder='Ваша заявка либо вопрос...'
            rows='5'
          />
          <div className='flex flex-wrap'>
            <input
              type='submit'
              value='Отправить'
              className=' self-center h-12 ml-4 px-8 py-2 uppercase text-sm font-bold text-white bg-red-500  rounded  hover:bg-red-700'
            />
            {/* {status === 'SUCCESS' ? (
              <p className='text-green-700'>Спасибо! Скоро мы ответим Вам!</p>
            ) : (
              
            )}
            {status === 'ERROR' && (
              <p className=' text-red-700'>
                Ooops! Кажется, Вы что то упустили?
              </p>
            )} */}
          </div>
          <input type='submit' name='_gotcha' className='hidden' />
        </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
